.navyblue-bg {
    background-color: #2B2D42;
    color: white;
    margin-right: auto;
}

.boxClient:hover,
.boxClient:focus {
    background-color: #e7e7e7;
    border-color: #CFD4E1;
    padding: 0;
    border-style: solid;
    border-width: 0.1em;
    box-shadow: 0px 0px 0px 0px;
}

.boxClient:after,
.boxClient:active {
    background-color: #FFFFFF;
    border-color: #CFD4E1;
    padding: 0;
    border-style: solid;
    border-width: 0.1em;
    box-shadow: 0px 0px 0px 0px;
}

/*res1*/
@media (min-width: 1200px) {
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 150px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 4%;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 10%;

    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .boxClient {
        width: 227px;
        height: 160px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
        /* border-right: hidden; */
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
    }
}


/*res2*/
@media (min-width: 988px) and (max-width:1199.98px) {
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 130px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 5%;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 10%;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .boxClient {
        width: 195px;
        height: 160px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
    }
}

/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 110px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 8%;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 14%;

    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .boxClient {
        width: 150px;
        height: 110px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
        /* border-right: hidden; */
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
        background-size: 30%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 25%;
    }
}

/*res4*/
@media (min-width: 655px) and (max-width:769.99px) {
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 80px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .boxClient {
        width: 128px;
        height: 100px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
    }

    .boxClient:hover,
    .boxClient:focus {
        background-color: #e7e7e7;
        border-color: #CFD4E1;
        padding: 0;
        border-style: solid;
        border-width: 0.1em;
        box-shadow: 0px 0px 0px 0px;
    }

    .boxClient:after,
    .boxClient:active {
        background-color: #FFFFFF;
        border-color: #CFD4E1;
        padding: 0;
        border-style: solid;
        border-width: 0.1em;
        box-shadow: 0px 0px 0px 0px;
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
        background-size: 30%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 25%;
    }
}

/*res5*/
@media (min-width: 528px) and (max-width:654.99px) {
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 60px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 20%;
    }

    .titlepageText {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        padding-top: 7%;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .boxClient {
        width: 95px;
        height: 80px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
        background-size: 30%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 25%;
    }

}

/*res6*/
@media (min-width: 400px) and (max-width:527.99px) {
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 45px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 20%;
    }

    .titlepageText {
        font-size: 26px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        padding-top: 7%;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .boxClient {
        width: 78px;
        height: 64px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
        padding-top: 5px;
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
        background-size: 30%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 25%;
    }

}

/*res7*/
@media (max-width: 399.99px){
    .rectangle-bg5 {
        background-image: url("../../img/bg/BG_white_our clients.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 5;
        padding-top: 5%
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 45px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 20%;
    }

    .titlepageText {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        padding-top: 9%;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
    }

    .boxClient {
        width: 60px;
        height: 50px;
        border-color: #CFD4E1;
        border-style: solid;
        border-width: 0.1em;
        border-radius: 0;
        padding: 0;
        background-color: #FFFFFF;
    }

    .bgredOurclient {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS.png");
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 0%;
        background-size: 30%;
    }

    .bgredOurclient2 {
        background-image: url("../../img/bg/BG_red_OUR CLIENTS2.png");
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 25%;
    }
}