.navyblue-bg {
    background-color: #2B2D42;
    color: white;
    margin-right: auto;

}

/*res1*/
@media (min-width: 1200px) {

    .rectangle-bg2 {
        background-image: url("../../img/bg/BG_white_OUR\ SERVICE.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 350px;
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 150px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 4%;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 10%;

    }

    .bgredservice {
        background-image: url("../../img/bg/BG_red_OUR SERVICE.png");
        background-size: 600.08px 509.26px;
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        margin-top: 3%;
        position: absolute;
        z-index: 0;
    }

    .rectangle-bg3 {
        background-image: url("../../img/bg/BG_white_webdesign.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 642px;
        z-index: 4;
        padding-top: 5%
    }

    .topicText {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
    }

    .imginbox {
        border-style: hidden;
        border-radius: 5px;
        width: 649px;
        height: 408px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .rectangle-bg4 {
        background-image: url("../../img/bg/Rectangle_1888.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 560px;
        z-index: 4;
        padding-top: 5%
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .boxtext {
        width: 40%;
    }

    .boxtext2 {
        width: 40%;
    }

    .textpadding1 {
        padding-top: 13%;
        padding-left: 7%;
        padding-bottom: 23%;
    }

    .textpadding2 {
        padding-top: 8%;
        padding-bottom: 5%;
        padding-right: 4%
    }

    .textpadding3 {
        padding-top: 12%;
        padding-left: 8%;
        padding-bottom: 18%
    }

    .textpadding4 {
        padding-top: 4%;
        padding-bottom: 5%;
        padding-right: 4%;
    }

    .imgpadding1 {
        padding-top: 7%;
        padding-bottom: 10%
    }

    .imgpadding2 {
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .imgpadding3 {
        padding: 7% 4% 7% 5%;

    }

    .imgpadding4 {
        padding-top: 0%;
        padding-bottom: 5%;
    }
}

/*res2*/
@media (min-width: 988px) and (max-width:1199.98px) {
    .rectangle-bg2 {
        background-image: url("../../img/bg/BG_white_OUR\ SERVICE.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 350px;
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 130px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 8%;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 12% !important;

    }

    .bgredservice {
        background-image: url("../../img/bg/BG_red_OUR SERVICE.png");
        background-size: 600.08px 509.26px;
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        margin-top: 3%;
        position: absolute;
        z-index: 0;
    }

    .rectangle-bg3 {
        background-image: url("../../img/bg/BG_white_webdesign.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 590px;
        z-index: 4;
        padding-top: 5%
    }

    .topicText {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
    }

    .imginbox {
        border-style: hidden;
        border-radius: 5px;
        width: 510px;
        height: 320px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 5%;
    }

    .rectangle-bg4 {
        background-image: url("../../img/bg/Rectangle_1888.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 500px;
        z-index: 4;
        padding-top: 5%
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .boxtext {
        width: 40%;
    }

    .boxtext2 {
        width: 40%;
    }

    .textpadding1 {
        padding-top: 13%;
        padding-left: 7%;
        padding-bottom: 23%;
    }

    .textpadding2 {
        padding-top: 8%;
        padding-bottom: 5%;
        padding-right: 4%
    }

    .textpadding3 {
        padding-top: 12%;
        padding-left: 8%;
        padding-bottom: 18%
    }

    .textpadding4 {
        padding-top: 4%;
        padding-bottom: 5%;
        padding-right: 4%;
    }

    .imgpadding1 {
        padding-top: 7%;
        padding-bottom: 10%
    }

    .imgpadding2 {
        padding-top: 0%;
        padding-bottom: 5%;
    }

    .imgpadding3 {
        padding: 7% 4% 7% 5%;

    }

    .imgpadding4 {
        padding-top: 0%;
        padding-bottom: 5%;
    }
}

/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {
    .rectangle-bg2 {
        background-image: url("../../img/bg/BG_white_OUR\ SERVICE.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 350px;
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 110px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 9%;
    }

    .titlepageText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 15%;

    }

    .bgredservice {
        background-image: url("../../img/bg/BG_red_OUR SERVICE.png");
        background-size: 600.08px 509.26px;
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        margin-top: 10%;
        position: absolute;
        z-index: 0;
        background-size: 400px;
    }

    .rectangle-bg3 {
        background-image: url("../../img/bg/BG_white_webdesign.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 488px;
        z-index: 4;
        padding-top: 5%;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        font-size: 26px;
    }

    .imginbox {
        border-style: hidden;
        border-radius: 5px;
        width: 357px;
        height: 225px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 17%;
    }

    .rectangle-bg4 {
        background-image: url("../../img/bg/Rectangle_1888.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 450px;
        z-index: 4;
        padding-top: 5%
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .boxtext {
        width: 100%;
        padding-right: 4%;
        padding-top: 10%;
        padding-left: 4%;
    }

    .boxtext2 {
        width: 100%;
        padding-right: 4%;
        padding-top: 5%;
        padding-left: 4%;
    }

    .rowReverseService {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .rowService2 {
        height: 360px !important;
    }
}


/*res4*/
@media (min-width: 655px) and (max-width:769.99px) {

    .rectangle-bg2 {
        background-image: url("../../img/bg/BG_white_OUR\ SERVICE.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 350px;
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 80px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 14%;

    }

    .titlepageText {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 20%;

    }

    .bgredservice {
        background-image: url("../../img/bg/BG_red_OUR SERVICE.png");
        background-size: 600.08px 509.26px;
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 14%;
        margin-top: 2%;
        position: absolute;
        z-index: 0;
        background-size: 400px;
    }

    .rectangle-bg3 {
        background-image: url("../../img/bg/BG_white_webdesign.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 492px;
        z-index: 4;
        padding-top: 5%
    }

    .topicText {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
    }

    .imginbox {
        border-style: hidden;
        border-radius: 5px;
        width: 357px;
        height: 225px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 13%;
    }

    .rectangle-bg4 {
        background-image: url("../../img/bg/Rectangle_1888.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 470px;
        z-index: 4;
        padding-top: 5%
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .boxtext {
        width: 100%;
        padding-right: 4%;
        padding-top: 13%;
        padding-left: 4%;
    }

    .boxtext2 {
        width: 100%;
        padding-right: 4%;
        padding-top: 10%;
        padding-left: 4%;
    }

    .rowReverseService {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .rowService1 {
        height: 350px !important;
    }

    .rowService2 {
        height: 280px !important;
    }

}


/*res5*/
@media (min-width: 400px) and (max-width:654.99px) {
    .rectangle-bg2 {
        background-image: url("../../img/bg/BG_white_OUR\ SERVICE.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 280px;
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 60px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 14%;

    }

    .titlepageText {
        font-size: 28px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 18%;

    }

    .bgredservice {
        background-image: url("../../img/bg/BG_red_OUR SERVICE.png");
        background-size: 600.08px 509.26px;
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 10%;
        margin-top: 2%;
        position: absolute;
        z-index: 0;
        background-size: 300px;
    }

    .rectangle-bg3 {
        background-image: url("../../img/bg/BG_white_webdesign.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 115% 525px;
        z-index: 4;
        padding-top: 5%
    }

    .topicText {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        font-size: 20px !important;
    }

    .imginbox {
        border-style: hidden;
        border-radius: 5px;
        width: 357px;
        height: 225px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 19%;
    }

    .rectangle-bg4 {
        background-image: url("../../img/bg/Rectangle_1888.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 105% 502px;
        z-index: 4;
        padding-top: 5%
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .boxtext {
        width: 100%;
        padding-right: 4%;
        padding-top: 16%;
        padding-left: 4%;
    }

    .boxtext2 {
        width: 100%;
        padding-right: 4%;
        padding-top: 10%;
        padding-left: 4%;
    }

    .rowReverseService {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .rowService1 {
        height: 300px !important;
    }

    .rowService2 {
        height: 200px !important;
    }

    img {
        vertical-align: super;
    }
}

/*res6*/
@media (max-width: 399.99px) {
    .rectangle-bg2 {
        background-image: url("../../img/bg/BG_white_OUR\ SERVICE.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100% 280px;
    }

    .bigredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 45px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 25% !important;

    }

    .titlepageText {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 22% !important;

    }

    .bgredservice {
        background-image: url("../../img/bg/BG_red_OUR SERVICE.png");
        background-size: 600.08px 509.26px;
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 10%;
        margin-top: 2%;
        position: absolute;
        z-index: 0;
        background-size: 300px;
    }

    .rectangle-bg3 {
        background-image: url("../../img/bg/BG_white_webdesign.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 111% 495px;
        z-index: 4;
        padding-top: 5%
    }

    .topicText {
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
        font-size: 20px !important;
    }

    .imginbox {
        border-style: hidden;
        border-radius: 5px;
        width: 300px;
        height: 189px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 19%;
    }

    .rectangle-bg4 {
        background-image: url("../../img/bg/Rectangle_1888.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 105% 483px;
        z-index: 4;
        padding-top: 5%
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
    }

    .boxtext {
        width: 100%;
        padding-right: 4%;
        padding-top: 19%;
        padding-left: 4%;
    }

    .boxtext2 {
        width: 100%;
        padding-right: 4%;
        padding-top: 10%;
        padding-left: 4%;
    }

    .rowReverseService {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .rowService1 {
        height: 300px !important;
    }

    .rowService2 {
        height: 200px !important;
    }

    img {
        vertical-align: super;
    }
}