.navyblue-bg {
    background-color: #2B2D42;
    color: white;
    margin-right: auto;

}

/*res1*/
@media (min-width: 1200px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 150px;
        z-index: 4;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
        z-index: 8;
    }

    .bgredaboutus {
        background-image: url("../../img/bg/BG_red_about us.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 14%;
        background-size: 32%;
    }

    .rectangle-bg6 {
        background-image: url("../../img/bg/BG_WHITE_CONTACT US.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .boxDetail {
        border-radius: 0.6em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        height: 332px;
        padding-top: 4%;
    }

    .bgiconinbox2 {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-top: 8px;
    }

    .iconinbox {
        margin-top: 30%;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px !important;
    }

    .bgredaboutus2 {
        background-image: url("../../img/bg/BG_red_about us-1.png");
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        background-size: 27%;
        padding-bottom: 10%;
    }
}

/*res2*/
@media (min-width: 988px) and (max-width:1199.98px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 130px;
        z-index: 4;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 7%;
        color: black;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px !important;
    }

    .bgredaboutus {
        background-image: url("../../img/bg/BG_red_about us.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 30%;
    }

    .rectangle-bg6 {
        background-image: url("../../img/bg/BG_WHITE_CONTACT US.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .boxDetail {
        border-radius: 0.6em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        height: 332px;
        padding-top: 6%;
    }

    .bgiconinbox2 {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-left: 20px;
        margin-top: 8px;
    }

    .iconinbox {
        margin-top: 30%;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px !important;
    }

    .bgredaboutus2 {
        background-image: url("../../img/bg/BG_red_about us-1.png");
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        background-size: 27%;
        padding-bottom: 10%;
    }
}

/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 110px;
        z-index: 4;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .bgredaboutus {
        background-image: url("../../img/bg/BG_red_about us.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 32%;
    }

    .rectangle-bg6 {
        background-image: url("../../img/bg/BG_WHITE_CONTACT US.png");
        color: black;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .boxDetail {
        border-radius: 0.6em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        height: 332px;
        padding-top: 4%;
    }

    .bgiconinbox2 {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-top: 8px;
    }

    .iconinbox {
        margin-top: 30%;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 20px !important;
    }

    .bgredaboutus2 {
        background-image: url("../../img/bg/BG_red_about us-1.png");
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        background-size: 27%;
        padding-bottom: 10%;
    }
}

/*res4*/
@media (min-width: 655px) and (max-width:769.99px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 110px;
        z-index: 4;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .bgredaboutus {
        background-image: url("../../img/bg/BG_red_about us.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 32%;
    }

    .rectangle-bg6 {
        background-image: url("../../img/bg/BG_WHITE_CONTACT US.png");
        color: black;
        background-repeat: no-repeat;
        /* background-size: 100%; */
    }

    .boxDetail {
        border-radius: 0.6em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        padding: 2%;
        height: 332px;
        padding-top: 6%;
    }

    .bgiconinbox2 {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-top: 8px;
        margin-bottom: 3px;
    }

    .iconinbox {
        margin-top: 30%;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 18x !important;
    }

    .bgredaboutus2 {
        background-image: url("../../img/bg/BG_red_about us-1.png");
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
    }
}

/*res5*/
@media (min-width: 400px) and (max-width:654.99px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 110px;
        z-index: 4;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .bgredaboutus {
        background-image: url("../../img/bg/BG_red_about us.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 32%;
    }

    .rectangle-bg6 {
        background-image: url("../../img/bg/BG_WHITE_CONTACT US.png");
        color: black;
        background-repeat: no-repeat;
        /* background-size: 100%; */
    }

    .boxDetail {
        border-radius: 0.6em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        padding: 2%;
        /* width: 416px; */
        height: 332px;
        padding-top: 6%;
    }

    .bgiconinbox2 {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 70px;
        width: 70px;
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .iconinbox {
        margin-top: 30%;
        width: 30px;
    }

    .bgredaboutus2 {
        background-image: url("../../img/bg/BG_red_about us-1.png");
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 14px !important;
    }
}

/*res6*/
@media (max-width: 399.99px){
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 45px;
        z-index: 4;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .bgredaboutus {
        background-image: url("../../img/bg/BG_red_about us.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
        background-size: 32%;
    }

    .rectangle-bg6 {
        background-image: url("../../img/bg/BG_WHITE_CONTACT US.png");
        color: black;
        background-repeat: no-repeat;
    }

    .boxDetail {
        border-radius: 0.6em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
        padding: 2%;
        height: 332px;
        padding-top: 6%;
    }

    .bgiconinbox2 {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 70px;
        width: 70px;
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .iconinbox {
        margin-top: 30%;
        width: 30px;
    }

    .bgredaboutus2 {
        background-image: url("../../img/bg/BG_red_about us-1.png");
        background-position: right 0% bottom 0%;
        background-repeat: no-repeat;
        padding-top: 7%;
    }

    .detailtext{
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 14px !important;
    }
}