.navyblue-bg {
    background-color: #2B2D42;
    color: white;
    margin-right: auto;
}

.rectangle-bg7 {
    background-image: url("../../img/bg/BG_map.png");
    color: black;
    background-repeat: no-repeat;
    background-size: 100%;
}

.form-control {
    font-family: 'Kanit', sans-serif;
}

.form-control:focus {
    font-family: 'Kanit', sans-serif;
    border-radius: 10%;
    border-width: 1;
    border-color: #EF233C;
    border-radius: 5px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.redbuttonCpage {
    border-radius: 100px;
    background-color: #EF233C;
    color: #FFFFFF;
    padding: 10px;
    margin-top: 1%;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Poppins', sans-serif;
}

.rectangle-bg8 {
    background-image: url("../../img/bg/Rectangle 1808.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left 0% bottom 0%;
    padding-bottom: 8%;
}

.iconsendmessage {
    padding-top: 20px;
}

/*res1*/
@media (min-width: 1200px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 150px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .headingIncontactpage {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 4%;
    }

    .HeadinginBox {
        font-size: 36px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        justify-content: center;
        font-weight: 700;
    }

    .bgredwewouldlove {
        background-image: url("../../img/bg/RED_MESSAGE.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
    }
}

/*res2*/
@media (min-width: 988px) and (max-width:1199.98px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 130px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 6%;
        color: black;
    }

    .headingIncontactpage {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 4%;
    }

    .HeadinginBox {
        font-size: 32px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        justify-content: center;
        font-weight: 700;

    }

    .bgredwewouldlove {
        background-image: url("../../img/bg/RED_MESSAGE.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
    }
}

/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {

    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 110px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .headingIncontactpage {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 4%;
    }

    .HeadinginBox {
        font-size: 26px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        justify-content: center;
        font-weight: 700;

    }

    .bgredwewouldlove {
        background-image: url("../../img/bg/RED_MESSAGE.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
    }
}


/*res4*/
@media (min-width: 655px) and (max-width:769.99px) {
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 80px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 4%;
    }

    .SubtitleText {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 9%;
        color: black;
    }

    .headingIncontactpage {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 4%;
    }

    .HeadinginBox {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        justify-content: center;
        font-weight: 700;
    }

    .bgredwewouldlove {
        background-image: url("../../img/bg/RED_MESSAGE.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        background-size: 90%;
    }

    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
        height: 700px;
    }

    .boxsendmassageSmall{
        padding-top: 31% !important;
        position: absolute;
        width: 90%;
    }
    .boxCenter{
        display: flex;
        justify-content: center;
    }

}


/*res5*/
@media (min-width: 400px) and (max-width:654.99px) {

    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 45px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 15px;
    }

    .SubtitleText {
        font-size: 26px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 6%;
        color: black;
    }

    .headingIncontactpage {
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 10%;
    }

    .HeadinginBox {
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        justify-content: center;
        font-weight: 700;
        padding-bottom: 10%;
    }

    .bgredwewouldlove {
        background-image: url("../../img/bg/RED_MESSAGE.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        background-size: 90%;
    }

    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
        height: 580px;
    }

    .boxsendmassageSmall{
        padding-top: 32% !important;
        position: absolute;
        width: 90%;
    }

    .form-control {
        font-size: 14px !important;
    }

    .redbuttonCpage {
        color: #FFFFFF;
        padding: 8px !important;
        margin-top: 0% !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        font-size: 12px !important;
        align-items: center !important;
        display: flex !important;
    }

    .boxCenter{
        display: flex;
        justify-content: center;
    }

}

@media (min-width: 552) and (max-width:580px) {
    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
        height: 558px !important;
    }
}

@media (min-width: 475px) and (max-width:552px) {
    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
        height: 527px !important;
    }
}

@media (min-width: 400px) and (max-width:475px) {
    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
        height: 476px !important;
    }
}

/*res6*/
@media (max-width: 399.99px){
    
    .TitleredText {
        color: Red;
        opacity: 10%;
        font-weight: 900;
        font-size: 45px;
        z-index: 4;
        left: 0%;
        font-family: 'Poppins', sans-serif;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 8px;
    }

    .SubtitleText {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        justify-content: center;
        font-weight: 900;
        z-index: 5;
        left: 0;
        width: 100%;
        margin-top: 5%;
        color: black;
    }

    .headingIncontactpage {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-top: 10%;
    }

    .HeadinginBox {
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        text-align: left;
        justify-content: center;
        font-weight: 700;
        padding-bottom: 10%;
    }

    .bgredwewouldlove {
        background-image: url("../../img/bg/RED_MESSAGE.png");
        background-position: left 0% top 0%;
        background-repeat: no-repeat;
        background-size: 90%;
    }

    .boxsendmassage {
        background-color: #FFFFFF;
        box-shadow: 0px 54px 120px -60px rgba(36, 50, 85, 0.17);
        border-radius: 6px;
        padding: 0%;
        width: 90%;
        height: 424px;
    }

    .boxsendmassageSmall{
        padding-top: 32% !important;
        position: absolute;
        width: 88%;
    }

    .form-control {
        font-size: 13px !important;
    }

    .redbuttonCpage {
        color: #FFFFFF;
        padding: 8px !important;
        margin-top: 0% !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
        font-size: 12px !important;
        align-items: center !important;
        display: flex !important;
    }

    .boxCenter{
        display: flex;
        justify-content: center;
    }

}