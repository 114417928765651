.navyblue-bg {
    background-color: #2B2D42;
    color: white;
    margin-right: auto;
}

.imgInLittleBox {
    margin-top: 9%;
}

.rectangle-bg1 {
    background-image: url("../../img/bg/BG_white_See more Services.png");
    color: black;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: 100%;
}

.icon-next {
    color: #EF233C;
    cursor: pointer;
    margin-left: 2em;
}

.icon-prev {
    color: #EF233C;
    cursor: pointer;
    margin-right: 2em;
}

.iconinbox {
    margin-top: 30%;
}

/*res1*/
@media (min-width: 1200px) {
    .box-heading {
        padding-top: 6%;
        padding-bottom: 7%;
        margin-left: 5%;
    }

    .heading1 {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .heading2 {
        font-size: 60px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .redtext {
        font-size: 96px;
        font-family: 'Poppins', sans-serif;
        font-weight: 1000;
        color: #FF5043;
        display: inline;
        margin-right: 1%;
        white-space: nowrap;
    }

    .imgheading {
        width: 50%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 1;
    }

    .textDetail {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 35px;
        text-align: left;
        justify-content: center;
    }

    .redbutton {
        border-radius: 100px;
        background-color: #EF233C;
        color: #FFFFFF;
        padding: 10px;
        margin-top: 2%;
        margin-bottom: 5%;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .bgredboxleft {
        background-image: url("../../img/bg/BG_See_more_Services.png");
        background-size: 320px 268px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-left: 2%;
    }

    .bgredboxmid {
        padding-top: 7%;
        background-color: #FFFFFF;
    }

    .bgredboxright {
        background-image: url("../../img/bg/BG_See_more_ Services-1.png");
        background-size: 320px 268px;
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-right: 2%;
    }

    .boxDetail {
        border-radius: 0.5em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        background-color: #FFFFFF;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        height: 332px;
        margin-top: 2%;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
    }

    .bgiconinbox {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-top: 8px;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .littlebox {
        background: #FFFFFF;
        border: 1px solid #CFD4E1;
        border-radius: 8px;
        padding: 2%;
        margin: 2%;
        color: black;
        text-align: center;
    }

    .SliderHeight{
        height: 200px;
    }

    .textrightside{
        justify-content: end;
    }

    
    .SliderFiveIcon{
        display: block;
        visibility: visible;
    }

    .SliderThreeIcon{
        display: none;
        visibility: hidden;
    }

}


/*res2*/
@media (min-width: 988px) and (max-width:1199.98px) {
    .heading1 {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .heading2 {
        font-size: 50px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .redtext {
        font-size: 80px;
        font-family: 'Poppins', sans-serif;
        font-weight: 1000;
        color: #FF5043;
        display: inline;
        margin-right: 1%;
        white-space: nowrap;
    }

    .box-heading {
        padding-top: 6%;
        padding-bottom: 7%;
        margin-left: 5%;
    }

    .imgheading {
        width: 45%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 1;
    }

    .textDetail {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 30px;
        text-align: left;
        justify-content: center;
    }

    .redbutton {
        border-radius: 100px;
        background-color: #EF233C;
        color: #FFFFFF;
        padding: 10px;
        margin-top: 2%;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .bgredboxleft {
        background-image: url("../../img/bg/BG_See_more_Services.png");
        background-size: 320px 268px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-left: 2%;
    }

    .bgredboxmid {
        padding-top: 7%;
        background-color: #FFFFFF;
    }

    .bgredboxright {
        background-image: url("../../img/bg/BG_See_more_ Services-1.png");
        background-size: 320px 268px;
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-right: 2%;
    }

    .boxDetail {
        border-radius: 0.5em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        background-color: #FFFFFF;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        /* width: 416px; */
        height: 332px;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
    }

    .bgiconinbox {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-left: 15px;
        margin-top: 8px;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .littlebox {
        background: #FFFFFF;
        border: 1px solid #CFD4E1;
        border-radius: 8px;
        padding: 2%;
        margin: 2%;
        color: black;
        text-align: center;
    }

    .SliderHeight{
        height: 200px;
    }

     .textrightside{
        justify-content: end;
    }

    .SliderFiveIcon{
        display: block;
        visibility: visible;
    }

    .SliderThreeIcon{
        display: none;
        visibility: hidden;
    }

}

/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {
    .heading1 {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .heading2 {
        font-size: 40px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .redtext {
        font-size: 60px;
        font-family: 'Poppins', sans-serif;
        font-weight: 1000;
        color: #FF5043;
        display: inline;
        margin-right: 1%;
        white-space: nowrap;
    }

    .box-heading {
        padding-top: 6%;
        padding-bottom: 7%;
        margin-left: 5%;
    }

    .imgheading {
        width: 45%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 1;
    }

    .textDetail {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 26px;
        text-align: left;
        justify-content: center;
    }


    .redbutton {
        border-radius: 100px;
        background-color: #EF233C;
        color: #FFFFFF;
        padding: 10px;
        margin-top: 2%;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .bgredboxleft {
        background-image: url("../../img/bg/BG_See_more_Services.png");
        background-size: 320px 268px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-left: 2%;
    }

    .bgredboxmid {
        padding-top: 7%;
        background-color: #FFFFFF;
    }

    .bgredboxright {
        background-image: url("../../img/bg/BG_See_more_ Services-1.png");
        background-size: 320px 268px;
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-right: 2%;
    }

    .boxDetail {
        border-radius: 0.5em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        background-color: #FFFFFF;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        /* width: 416px; */
        height: 332px;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
    }

    .bgiconinbox {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-top: 8px;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
    }

    .littlebox {
        background: #FFFFFF;
        border: 1px solid #CFD4E1;
        border-radius: 8px;
        padding: 2%;
        margin: 2%;
        color: black;
        text-align: center;
    }

    .SliderHeight{
        height: 180px;
    }

    .SliderFiveIcon{
        display: block;
        visibility: visible;
    }

    .SliderThreeIcon{
        display: none;
        visibility: hidden;
    }
}

/*res4*/
@media (min-width: 655px) and (max-width:769.99px) {
    .heading1 {
        font-size: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .heading2 {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .redtext {
        font-size: 55px;
        font-family: 'Poppins', sans-serif;
        font-weight: 1000;
        color: #FF5043;
        display: inline;
        margin-right: 1%;
        white-space: nowrap;
    }

    .box-heading {
        padding-top: 6%;
        padding-bottom: 7%;
        margin-left: 5%;
    }

    .imgheading {
        width: 45%;
        position: absolute;
        right: 0;
        height: 100%;
        z-index: 1;
    }

    .textDetail {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 20px;
        text-align: left;
        justify-content: center;
    }

    .redbutton {
        border-radius: 100px;
        background-color: #EF233C;
        color: #FFFFFF;
        padding: 10px;
        margin-top: 2%;
        padding-left: 20px;
        padding-right: 20px;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .bgredboxleft {
        background-image: url("../../img/bg/BG_See_more_Services.png");
        background-size: 320px 268px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-left: 2%;
    }

    .bgredboxmid {
        padding-top: 7%;
        background-color: #FFFFFF;
    }

    .bgredboxright {
        background-image: url("../../img/bg/BG_See_more_ Services-1.png");
        background-size: 320px 268px;
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-right: 2%;
    }

    .boxDetail {
        border-radius: 0.5em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        background-color: #FFFFFF;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        padding: 2%;
        height: 332px;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
    }

    .bgiconinbox {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 80px;
        width: 80px;
        margin-top: 8px;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .littlebox {
        background: #FFFFFF;
        border: 1px solid #CFD4E1;
        border-radius: 8px;
        padding: 2%;
        color: black;
        text-align: center;
        margin: 1%;
    }

    .rowReverse {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .textLeft {
        text-align: left !important;
        padding-left: 5%;
    }

    .icon-next {
        color: #EF233C;
        width: 25px !important;
        height: 25px !important;
    }

    .icon-prev {
        color: #EF233C;
        width: 25px !important;
        height: 25px !important;
    }

    .SliderHeight{
        height: 130px;
    }

    .textBoxSize{
        width: 96%;
    }

    .SliderFiveIcon{
        display: block;
        visibility: visible;
    }

    .SliderThreeIcon{
        display: none;
        visibility: hidden;
    }

}



/*res5*/
@media (min-width: 400px) and (max-width:654.99px) {
    .heading1 {
        font-size: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .heading2 {
        font-size: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .redtext {
        font-size: 50px;
        font-family: 'Poppins', sans-serif;
        font-weight: 1000;
        color: #FF5043;
        display: inline;
        margin-right: 1%;
        white-space: nowrap;
    }

    .box-heading {
        padding-top: 6%;
        padding-bottom: 7%;
        margin-left: 4%;
    }

    .imgheading {
        width: 0%;
        position: absolute;
        right: 0;
        height: 0%;
        z-index: 1;
    }

    .textDetail {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        text-align: left;
        justify-content: center;
    }

    .redbutton {
        border-radius: 100px;
        background-color: #EF233C;
        color: #FFFFFF;
        padding: 6px;
        margin-top: 2%;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 6px;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .bgredboxleft {
        background-image: url("../../img/bg/BG_See_more_Services.png");
        background-size: 320px 268px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-left: 2%;
    }

    .bgredboxmid {
        padding-top: 7%;
        background-color: #FFFFFF;
    }

    .bgredboxright {
        background-image: url("../../img/bg/BG_See_more_ Services-1.png");
        background-size: 320px 268px;
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-right: 2%;
    }

    .boxDetail {
        border-radius: 0.5em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        background-color: #FFFFFF;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        padding: 2%;
        height: 332px;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
    }

    .bgiconinbox {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 60px;
        width: 60px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
    }

    .littlebox {
        background: #FFFFFF;
        border: 1px solid #CFD4E1;
        border-radius: 8px;
        padding: 1%;
        color: black;
        text-align: center;
        margin: 1%;
    }

    .rowReverse {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .textLeft {
        text-align: left !important;
        padding-left: 5%;
    }

   
    .icon-next {
        color: #EF233C;
        width: 25px !important;
        height: 25px !important;
        margin-left: 0 !important;
    }

    .icon-prev {
        color: #EF233C;
        width: 25px !important;
        height: 25px !important;
        margin-right: 0 !important;
    }

    img{
        vertical-align: super;
    }

    .SliderHeight{
        height: 50px;
    }

    .topicTextSpace{
        padding-Top: 11% !important;
        padding-bottom: 9% !important;
    }
    .textBoxSize{
        width: 96%;
    }

    .SliderFiveIcon{
        display: none;
        visibility: hidden;
    }

    .SliderThreeIcon{
        display: block;
        visibility: visible;
    }
}


/*res6*/
@media (max-width: 399.99px){
    .heading1 {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        display: inline;
        letter-spacing: 2px;
        vertical-align: text-bottom;
        white-space: nowrap;
    }

    .heading2 {
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
    }

    .redtext {
        font-size: 42px;
        font-family: 'Poppins', sans-serif;
        font-weight: 1000;
        color: #FF5043;
        display: inline;
        margin-right: 1%;
        white-space: nowrap;
    }

    .box-heading {
        padding-top: 6%;
        padding-bottom: 7%;
        margin-left: 2%;
    }

    .imgheading {
        width: 0%;
        position: absolute;
        right: 0;
        height: 0%;
        z-index: 1;
    }

    .textDetail {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        text-align: left;
        justify-content: center;
    }

    .redbutton {
        border-radius: 100px;
        background-color: #EF233C;
        color: #FFFFFF;
        padding: 6px;
        margin-top: 2%;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 6px;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }

    .bgredboxleft {
        background-image: url("../../img/bg/BG_See_more_Services.png");
        background-size: 320px 268px;
        background-position: 0% 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-left: 2%;
    }

    .bgredboxmid {
        padding-top: 7%;
        background-color: #FFFFFF;
    }

    .bgredboxright {
        background-image: url("../../img/bg/BG_See_more_ Services-1.png");
        background-size: 320px 268px;
        background-position: right 0% top 0%;
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        padding-top: 7%;
        padding-right: 2%;
    }

    .boxDetail {
        border-radius: 0.5em;
        border-style: solid;
        border-width: 0.1em;
        border-color: #CFD4E1;
        background-color: #FFFFFF;
        text-align: center;
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
        padding: 2%;
        height: 332px;
    }

    .topicText {
        font-weight: 600;
        font-family: 'Kanit', sans-serif;
        font-size: 24px;
    }

    .bgiconinbox {
        border-radius: 50%;
        background-color: #F2F2F2;
        height: 60px;
        width: 60px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .normalEngText {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
    }

    .normalThaiText {
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
    }

    .littlebox {
        background: #FFFFFF;
        border: 1px solid #CFD4E1;
        border-radius: 8px;
        padding: 0%;
        color: black;
        text-align: center;
        margin: 1%;
    }

    .rowReverse {
        padding-top: 5%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .textLeft {
        text-align: left !important;
        padding-left: 5%;
    }

   
    .icon-next {
        color: #EF233C;
        width: 25px !important;
        height: 25px !important;
        margin-left: 0 !important;
    }

    .icon-prev {
        color: #EF233C;
        width: 25px !important;
        height: 25px !important;
        margin-right: 0 !important;
    }

    img{
        vertical-align: super;
    }

    .SliderHeight{
        height: 50px;
    }

    .textBoxSize{
        width: 100%;
    }

    .SliderFiveIcon{
        display: none;
        visibility: hidden;
    }

    .SliderThreeIcon{
        display: block;
        visibility: visible;
    }
}