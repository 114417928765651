.navyblue-bg {
    background-color: #2B2D42;
    color: white;
    margin-right: auto;

}

.black-bg {
    background-color: black;
}

.bi-facebook,
.bi-facebook:active,
.bi-facebook:focus {
    color: #FFFFFF;
}


/*res1*/
@media (min-width: 1200px) {
    .headingText {
        font-size: 36px;
    }

    .normalText {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    }

    .iconSize {
        width: 28px;
        height: 28px;
    }

    .littleredbox {
        background: #FF5043;
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
        width: 10%;
        margin-left: 5%;
        font-family: 'Poppins', sans-serif;
    }

    .littleredbox2{
        background: #FF5043;
        padding-left: 10%;
    }

}

/*res2*/
@media (min-width: 988px) and (max-width:1199.98px) {
    .headingText {
        font-size: 32px;
    }

    .normalText {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        margin-left: 5px;
    }

    .iconSize {
        width: 28px;
        height: 28px;
    }

    .littleredbox {
        background: #FF5043;
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
        width: 10%;
        margin-left: 5%;
        font-family: 'Poppins', sans-serif;
    }

    .littleredbox2{
        background: #FF5043;
        padding-left: 5%;
    }

}

/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {
    .headingText {
        font-size: 26px;
    }

    .normalText {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        margin-left: 10px;
    }

    .iconSize {
        width: 28px;
        height: 28px;
    }

    .littleredbox {
        background: #FF5043;
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
        width: 40%;
        margin-left: 5%;
        font-family: 'Poppins', sans-serif;
        margin-right: 2%;
    }
    .littleredbox2{
        background: #FF5043;
        padding-left: 5%;
    }


}

/*res4*/
@media (min-width: 577px) and (max-width:769.99px) {
    .headingText {
        font-size: 20px;
    }

    .normalText {
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        margin-left: 5px;
    }

    .iconSize {
        width: 20px;
        height: 20px;
    }

    .littleredbox {
        background: #FF5043;
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
        width: 34%;
        margin-left: 5%;
        font-family: 'Poppins', sans-serif;
        margin-right: 2%;
    }
    .littleredbox2{
        background: #FF5043;
        padding-left: 5%;
    }

}

/*res5*/
@media (min-width: 200px) and (max-width:576px) {
    .headingText {
        font-size: 22px;
    }

    .normalText {
        font-size: 12px;
        font-family: 'Poppins', sans-serif;
        margin-left: 5px;
        margin-top: 10px;
    }

    .iconSize {
        width: 20px;
        height: 20px;
        margin-top: 10px;
        margin-left: 22px;
    }

    .littleredbox2 {
        background-color: black;
        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.05);
        width: 40%;
        margin-left: 5%;
        font-family: 'Poppins', sans-serif;
        margin-right: 2%;
    }
    
    .bi-facebook{
        width: 30px;
        height: 30px;
    }

     .littleredbox2{
        padding-left: 5%;
    }

}