.nav-link.active {
    background-color: none;
    border-color: none;
    border-bottom: 3px solid red;
    font-weight: bold;
    display: inline-block;
}

.nav-item {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
}

.topbar {
    top: 0;
    background-color: #FFFFFF;
    z-index: 100;
}

.topbarright {
    justify-content: end;
    margin-right: 4%;
}

.logo {
    width: 159px;
}

.bg-light {
    background-color: #FFFFFF !important;
}

.navbar-light .navbar-nav .nav-link {
    color: black !important;
}

.navbar-toggler {
    border: none;
    margin-right: 30px;
}


/*res3*/
@media (min-width: 770px) and (max-width:987.99px) {
    .logo {
        width: 130px;
    }

    .nav-item {
        font-size: 16px !important;
        font-family: 'Poppins', sans-serif;
    }
}


/*res4*/
@media (min-width: 655px) and (max-width:769.99px) {
    .logo {
        width: 100px;
    }

    .nav-item {
        font-size: 16px !important;
        font-family: 'Poppins', sans-serif;
    }
    .icon-bar {
        height: 25px !important; 
        width: 25px !important;
    }
}

/*res5*/
@media (min-width: 300px) and (max-width:654.99px) {
    .logo {
        width: 100px;
        margin-left: 30px !important;
    }

    .nav-item {
        font-size: 14px !important;
        font-family: 'Poppins', sans-serif;
    }

    .icon-bar {
        height: 25px !important;
        width: 25px !important;
    }
}